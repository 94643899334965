// Run this example by adding <%= javascript_pack_tag 'hello_react' %> to the head of your layout file,
// like app/views/layouts/application.html.erb. All it does is render <div>Hello React</div> at the bottom
// of the page.

import React from 'react'
import axios from 'axios';

const FULFLLD_STATUS_LABELS = {
  pickupInTransit: 'En route to pickup',
  pickupArrived: 'Arrived at pickup',
  pickupCompleted: 'Pickup completed',
  dropoffIntransit: 'En route to delivery',
  dropoffArrived: 'Arrived at delivery',
  dropoffCompleted: 'Delivery completed'
};

const EventList = (props) => {
  const {
    name,
    time,
    timeZone,
  } = props;

  const timeString = new Date(time).toLocaleString(
    'en-US',
    {
      timeZoneName: 'short',
    }
  );

  return(
    <li className="list-group-item">
      <strong>{name}</strong>
      <br></br>
      <span>{timeString}</span>
    </li>
  )
}

class OnfleetEvents extends React.Component {
  _isMounted = false;

  state = {
    loaded: false,
    events: [],
    task: null,
    error: false,
  }

  componentDidMount() {
    this._isMounted = true;
    this.loadActivitiesFromServer()
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state,callback)=>{
      return;
    };
  }

  loadActivitiesFromServer = async () => {
    if (!this._isMounted) return;

    try {
      const taskLoader = this.props.courierPartner === 'fulflld' 
        ? this.loadFulflldTask 
        : this.loadOnfleetTask;

      const { events, task } = await taskLoader();
      
      this.setState({ 
        events,
        task,
        loaded: true 
      });
    } catch (error) {
      this.setState({
        error: true, 
        loaded: true
      });
    }
  }

  loadFulflldTask = async () => {
    try {
      const response = await axios.get(`/api/fulflld_orders/${this.props.taskId}`);
      console.log(response.data);
      const statusDetails = response.data.body[0].statusDetails || {};
      const events = Object.entries(statusDetails)
        .filter(([_, time]) => time) // Filter out null/undefined timestamps
        .map(([status, time]) => ({
          name: FULFLLD_STATUS_LABELS[status] || status,
          time: time
        }))
        .sort((b, a) => new Date(a.time) - new Date(b.time));

      return {
        events,
        task: response.data
      };
    } catch (error) {
      console.error('Error loading Fulflld task details:', error);
      throw error;
    }
  }

  loadOnfleetTask = async () => {
    try {
      const response = await axios.get(`/api/onfleet_tasks/${this.props.taskId}`);
      return {
        events: response.data.completion_details.events,
        task: response.data
      };
    } catch (error) {
      console.error('Error loading Onfleet task details:', error);
      throw error;
    }
  }

  render() {
    const listComponents = this.state.events.map((event) => (
      <EventList
        key={event.name}
        name={event.name}
        time={event.time}
        timeZone={this.props.timeZone}
      />
    ));

    let imageUrl = null;

    if (this.state.task && this.props.courierPartner !== 'fulflld') {
      // Add created event only for Onfleet tasks
      listComponents.unshift(
        <EventList
          key='created'
          name='Task created'
          time={this.state.task.time_created}
          timeZone={this.props.timeZone}
        />
      );

      const completionDetails = this.state.task.completion_details;

      if (Object.prototype.hasOwnProperty.call(completionDetails, 'success')) {
        const completed_title = completionDetails.success ? 'Task completed' : 'Task failed';
        const successTask = (
          <EventList
            key='completion'
            name={completed_title}
            time={completionDetails.time}
            timeZone={this.props.timeZone}
          />
        );
        listComponents.push(successTask);
      }

      if (Object.prototype.hasOwnProperty.call(completionDetails, 'photoUploadId')) {
        imageUrl = completionDetails.photoUploadId;
      }
    }

    return (
      <React.Fragment>
        <div className="panel-heading">
          Timeline
          <code className="pull-right">{this.props.shortTaskId}</code>
        </div>

        <ul className="list-group"> 
          {listComponents}
        </ul>

        {imageUrl && (
          <img src={`https://d15p8tr8p0vffz.cloudfront.net/${imageUrl}/800x.png`} width='300px' alt="Delivery photo" />
        )}
      </React.Fragment>
    );
  }
}

export default OnfleetEvents;
